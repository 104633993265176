<template>
  <div class="review-active-sidebar">
    <div style="display: flex; align-items: center; padding: 40px">
      <CRIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        color="red"
        style="flex-shrink: 0"
      >
        alert_outline
      </CRIcon>
      <p style="color: #e10055; margin: 0; padding-left: 10px">
        {{ warningMessage }}
      </p>
    </div>
    <div class="review-active-sidebar--button-spacer"></div>
    <v-btn
      id="review-active-save-button"
      class="review-active-save-button"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="toggleReviewActive"
    >
      {{ submitButtonText }}
    </v-btn>
  </div>
</template>

<script>
import reviews from '@/services/reviews'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    warningMessage() {
      return `${this.rowData?.isActive ? 'Remove' : 'Enable'} review for ${
        this.rowData?.company?.name
      } from reservation ${this.rowData?.managedId} `
    },
    submitButtonText() {
      return `${this.rowData?.isActive ? 'Disable' : 'Enable'} Review`
    },
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async toggleReviewActive() {
      if (this.rowData?.isActive) {
        await reviews.disableReview(this.rowData?.reservationReviewId)
      } else {
        await reviews.enableReview(this.rowData?.reservationReviewId)
      }
      EventBus.$emit('refresh-reviews')
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.review-active-sidebar {
  height: 100%;
  width: 500px;

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }
}

.review-active-save-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: inherit;
  height: 71px;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  width: 500px;
}
</style>
